/**
 * SendinBlue API
 * SendinBlue provide a RESTFul API that can be used with any languages. With this API, you will be able to :   - Manage your campaigns and get the statistics   - Manage your contacts   - Send transactional Emails and SMS   - and much more...  You can download our wrappers at https://github.com/orgs/sendinblue  **Possible responses**   | Code | Message |   | :-------------: | ------------- |   | 200  | OK. Successful Request  |   | 201  | OK. Successful Creation |   | 202  | OK. Request accepted |   | 204  | OK. Successful Update/Deletion  |   | 400  | Error. Bad Request  |   | 401  | Error. Authentication Needed  |   | 402  | Error. Not enough credit, plan upgrade needed  |   | 403  | Error. Permission denied  |   | 404  | Error. Object does not exist |   | 405  | Error. Method not allowed  |   | 406  | Error. Not Acceptable  | 
 *
 * The version of the OpenAPI document: 3.0.0
 * Contact: contact@sendinblue.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { GetCampaignStats } from './getCampaignStats';
import { GetDeviceBrowserStats } from './getDeviceBrowserStats';
import { GetStatsByDevice } from './getStatsByDevice';

export class GetExtendedCampaignStats {
    /**
    * Overall statistics of the campaign
    */
    'globalStats': GetCampaignStats;
    /**
    * List-wise statistics of the campaign.
    */
    'campaignStats': Array<GetCampaignStats>;
    /**
    * Number of clicks on mirror link
    */
    'mirrorClick': number;
    /**
    * Number of remaning emails to send
    */
    'remaining': number;
    /**
    * Statistics about the number of clicks for the links
    */
    'linksStats': object;
    'statsByDomain': { [key: string]: GetCampaignStats; };
    'statsByDevice': GetStatsByDevice;
    'statsByBrowser': { [key: string]: GetDeviceBrowserStats; };

    static discriminator: string | undefined = undefined;

    static attributeTypeMap: Array<{name: string, baseName: string, type: string}> = [
        {
            "name": "globalStats",
            "baseName": "globalStats",
            "type": "GetCampaignStats"
        },
        {
            "name": "campaignStats",
            "baseName": "campaignStats",
            "type": "Array<GetCampaignStats>"
        },
        {
            "name": "mirrorClick",
            "baseName": "mirrorClick",
            "type": "number"
        },
        {
            "name": "remaining",
            "baseName": "remaining",
            "type": "number"
        },
        {
            "name": "linksStats",
            "baseName": "linksStats",
            "type": "object"
        },
        {
            "name": "statsByDomain",
            "baseName": "statsByDomain",
            "type": "{ [key: string]: GetCampaignStats; }"
        },
        {
            "name": "statsByDevice",
            "baseName": "statsByDevice",
            "type": "GetStatsByDevice"
        },
        {
            "name": "statsByBrowser",
            "baseName": "statsByBrowser",
            "type": "{ [key: string]: GetDeviceBrowserStats; }"
        }    ];

    static getAttributeTypeMap() {
        return GetExtendedCampaignStats.attributeTypeMap;
    }
}

