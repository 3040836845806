/**
 * SendinBlue API
 * SendinBlue provide a RESTFul API that can be used with any languages. With this API, you will be able to :   - Manage your campaigns and get the statistics   - Manage your contacts   - Send transactional Emails and SMS   - and much more...  You can download our wrappers at https://github.com/orgs/sendinblue  **Possible responses**   | Code | Message |   | :-------------: | ------------- |   | 200  | OK. Successful Request  |   | 201  | OK. Successful Creation |   | 202  | OK. Request accepted |   | 204  | OK. Successful Update/Deletion  |   | 400  | Error. Bad Request  |   | 401  | Error. Authentication Needed  |   | 402  | Error. Not enough credit, plan upgrade needed  |   | 403  | Error. Permission denied  |   | 404  | Error. Object does not exist |   | 405  | Error. Method not allowed  |   | 406  | Error. Not Acceptable  | 
 *
 * The version of the OpenAPI document: 3.0.0
 * Contact: contact@sendinblue.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export class GetSmsEventReportEvents {
    /**
    * Phone number which has generated the event
    */
    'phoneNumber'?: string;
    /**
    * UTC date-time on which the event has been generated
    */
    'date'?: string;
    /**
    * Message ID which generated the event
    */
    'messageId'?: string;
    /**
    * Event which occurred
    */
    'event'?: GetSmsEventReportEvents.EventEnum;
    /**
    * Reason of bounce (only available if the event is hardbounce or softbounce)
    */
    'reason'?: string;
    'reply'?: string;
    /**
    * Tag of the SMS which generated the event
    */
    'tag'?: string;

    static discriminator: string | undefined = undefined;

    static attributeTypeMap: Array<{name: string, baseName: string, type: string}> = [
        {
            "name": "phoneNumber",
            "baseName": "phoneNumber",
            "type": "string"
        },
        {
            "name": "date",
            "baseName": "date",
            "type": "string"
        },
        {
            "name": "messageId",
            "baseName": "messageId",
            "type": "string"
        },
        {
            "name": "event",
            "baseName": "event",
            "type": "GetSmsEventReportEvents.EventEnum"
        },
        {
            "name": "reason",
            "baseName": "reason",
            "type": "string"
        },
        {
            "name": "reply",
            "baseName": "reply",
            "type": "string"
        },
        {
            "name": "tag",
            "baseName": "tag",
            "type": "string"
        }    ];

    static getAttributeTypeMap() {
        return GetSmsEventReportEvents.attributeTypeMap;
    }
}

export namespace GetSmsEventReportEvents {
    export enum EventEnum {
        Bounces = <any> 'bounces',
        HardBounces = <any> 'hardBounces',
        SoftBounces = <any> 'softBounces',
        Delivered = <any> 'delivered',
        Sent = <any> 'sent',
        Accepted = <any> 'accepted',
        Unsubscription = <any> 'unsubscription',
        Replies = <any> 'replies',
        Blocked = <any> 'blocked'
    }
}
