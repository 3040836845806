/**
 * SendinBlue API
 * SendinBlue provide a RESTFul API that can be used with any languages. With this API, you will be able to :   - Manage your campaigns and get the statistics   - Manage your contacts   - Send transactional Emails and SMS   - and much more...  You can download our wrappers at https://github.com/orgs/sendinblue  **Possible responses**   | Code | Message |   | :-------------: | ------------- |   | 200  | OK. Successful Request  |   | 201  | OK. Successful Creation |   | 202  | OK. Request accepted |   | 204  | OK. Successful Update/Deletion  |   | 400  | Error. Bad Request  |   | 401  | Error. Authentication Needed  |   | 402  | Error. Not enough credit, plan upgrade needed  |   | 403  | Error. Permission denied  |   | 404  | Error. Object does not exist |   | 405  | Error. Method not allowed  |   | 406  | Error. Not Acceptable  | 
 *
 * The version of the OpenAPI document: 3.0.0
 * Contact: contact@sendinblue.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export class EmailExportRecipients {
    /**
    * Webhook called once the export process is finished. For reference, https://help.sendinblue.com/hc/en-us/articles/360007666479
    */
    'notifyURL'?: string;
    /**
    * Type of recipients to export for a campaign
    */
    'recipientsType': EmailExportRecipients.RecipientsTypeEnum;

    static discriminator: string | undefined = undefined;

    static attributeTypeMap: Array<{name: string, baseName: string, type: string}> = [
        {
            "name": "notifyURL",
            "baseName": "notifyURL",
            "type": "string"
        },
        {
            "name": "recipientsType",
            "baseName": "recipientsType",
            "type": "EmailExportRecipients.RecipientsTypeEnum"
        }    ];

    static getAttributeTypeMap() {
        return EmailExportRecipients.attributeTypeMap;
    }
}

export namespace EmailExportRecipients {
    export enum RecipientsTypeEnum {
        All = <any> 'all',
        NonClickers = <any> 'nonClickers',
        NonOpeners = <any> 'nonOpeners',
        Clickers = <any> 'clickers',
        Openers = <any> 'openers',
        SoftBounces = <any> 'softBounces',
        HardBounces = <any> 'hardBounces',
        Unsubscribed = <any> 'unsubscribed'
    }
}
