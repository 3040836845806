/**
 * SendinBlue API
 * SendinBlue provide a RESTFul API that can be used with any languages. With this API, you will be able to :   - Manage your campaigns and get the statistics   - Manage your contacts   - Send transactional Emails and SMS   - and much more...  You can download our wrappers at https://github.com/orgs/sendinblue  **Possible responses**   | Code | Message |   | :-------------: | ------------- |   | 200  | OK. Successful Request  |   | 201  | OK. Successful Creation |   | 202  | OK. Request accepted |   | 204  | OK. Successful Update/Deletion  |   | 400  | Error. Bad Request  |   | 401  | Error. Authentication Needed  |   | 402  | Error. Not enough credit, plan upgrade needed  |   | 403  | Error. Permission denied  |   | 404  | Error. Object does not exist |   | 405  | Error. Method not allowed  |   | 406  | Error. Not Acceptable  | 
 *
 * The version of the OpenAPI document: 3.0.0
 * Contact: contact@sendinblue.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { GetExtendedCampaignOverviewAllOfSender } from './getExtendedCampaignOverviewAllOfSender';

export class GetExtendedCampaignOverviewAllOf {
    /**
    * Retrieved the status of test email sending. (true=Test email has been sent  false=Test email has not been sent)
    */
    'testSent': boolean;
    /**
    * Header of the campaign
    */
    'header': string;
    /**
    * Footer of the campaign
    */
    'footer': string;
    'sender': GetExtendedCampaignOverviewAllOfSender;
    /**
    * Email defined as the \"Reply to\" of the campaign
    */
    'replyTo': string;
    /**
    * Customisation of the \"to\" field of the campaign
    */
    'toField'?: string;
    /**
    * HTML content of the campaign
    */
    'htmlContent': string;
    /**
    * Link to share the campaign on social medias
    */
    'shareLink'?: string;
    /**
    * Tag of the campaign
    */
    'tag'?: string;
    /**
    * Creation UTC date-time of the campaign (YYYY-MM-DDTHH:mm:ss.SSSZ)
    */
    'createdAt': string;
    /**
    * UTC date-time of last modification of the campaign (YYYY-MM-DDTHH:mm:ss.SSSZ)
    */
    'modifiedAt': string;
    /**
    * Status of inline image. inlineImageActivation = false means image can’t be embedded, & inlineImageActivation = true means image can be embedded, in the email.
    */
    'inlineImageActivation'?: boolean;
    /**
    * Status of mirror links in campaign. mirrorActive = false means mirror links are deactivated, & mirrorActive = true means mirror links are activated, in the campaign
    */
    'mirrorActive'?: boolean;
    /**
    * FOR TRIGGER ONLY ! Type of trigger campaign.recurring = false means contact can receive the same Trigger campaign only once, & recurring = true means contact can receive the same Trigger campaign several times
    */
    'recurring'?: boolean;
    /**
    * Sent UTC date-time of the campaign (YYYY-MM-DDTHH:mm:ss.SSSZ). Only available if \'status\' of the campaign is \'sent\'
    */
    'sentDate'?: string;
    /**
    * Total number of non-delivered campaigns for a particular campaign id.
    */
    'returnBounce'?: number;

    static discriminator: string | undefined = undefined;

    static attributeTypeMap: Array<{name: string, baseName: string, type: string}> = [
        {
            "name": "testSent",
            "baseName": "testSent",
            "type": "boolean"
        },
        {
            "name": "header",
            "baseName": "header",
            "type": "string"
        },
        {
            "name": "footer",
            "baseName": "footer",
            "type": "string"
        },
        {
            "name": "sender",
            "baseName": "sender",
            "type": "GetExtendedCampaignOverviewAllOfSender"
        },
        {
            "name": "replyTo",
            "baseName": "replyTo",
            "type": "string"
        },
        {
            "name": "toField",
            "baseName": "toField",
            "type": "string"
        },
        {
            "name": "htmlContent",
            "baseName": "htmlContent",
            "type": "string"
        },
        {
            "name": "shareLink",
            "baseName": "shareLink",
            "type": "string"
        },
        {
            "name": "tag",
            "baseName": "tag",
            "type": "string"
        },
        {
            "name": "createdAt",
            "baseName": "createdAt",
            "type": "string"
        },
        {
            "name": "modifiedAt",
            "baseName": "modifiedAt",
            "type": "string"
        },
        {
            "name": "inlineImageActivation",
            "baseName": "inlineImageActivation",
            "type": "boolean"
        },
        {
            "name": "mirrorActive",
            "baseName": "mirrorActive",
            "type": "boolean"
        },
        {
            "name": "recurring",
            "baseName": "recurring",
            "type": "boolean"
        },
        {
            "name": "sentDate",
            "baseName": "sentDate",
            "type": "string"
        },
        {
            "name": "returnBounce",
            "baseName": "returnBounce",
            "type": "number"
        }    ];

    static getAttributeTypeMap() {
        return GetExtendedCampaignOverviewAllOf.attributeTypeMap;
    }
}

