/**
 * SendinBlue API
 * SendinBlue provide a RESTFul API that can be used with any languages. With this API, you will be able to :   - Manage your campaigns and get the statistics   - Manage your contacts   - Send transactional Emails and SMS   - and much more...  You can download our wrappers at https://github.com/orgs/sendinblue  **Possible responses**   | Code | Message |   | :-------------: | ------------- |   | 200  | OK. Successful Request  |   | 201  | OK. Successful Creation |   | 202  | OK. Request accepted |   | 204  | OK. Successful Update/Deletion  |   | 400  | Error. Bad Request  |   | 401  | Error. Authentication Needed  |   | 402  | Error. Not enough credit, plan upgrade needed  |   | 403  | Error. Permission denied  |   | 404  | Error. Object does not exist |   | 405  | Error. Method not allowed  |   | 406  | Error. Not Acceptable  | 
 *
 * The version of the OpenAPI document: 3.0.0
 * Contact: contact@sendinblue.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { SendEmailAttachment } from './sendEmailAttachment';

export class SendEmail {
    /**
    * List of the email addresses of the recipients. For example, [\'abc@example.com\', \'asd@example.com\'].
    */
    'emailTo': Array<string>;
    /**
    * List of the email addresses of the recipients in bcc
    */
    'emailBcc'?: Array<string>;
    /**
    * List of the email addresses of the recipients in cc
    */
    'emailCc'?: Array<string>;
    /**
    * Email address which shall be used by campaign recipients to reply back
    */
    'replyTo'?: string;
    /**
    * Absolute url of the attachment (no local file). Extension allowed: xlsx, xls, ods, docx, docm, doc, csv, pdf, txt, gif, jpg, jpeg, png, tif, tiff, rtf, bmp, cgm, css, shtml, html, htm, zip, xml, ppt, pptx, tar, ez, ics, mobi, msg, pub and eps
    */
    'attachmentUrl'?: string;
    /**
    * Pass the list of content (base64 encoded) and name of the attachment. For example, [{\"content\":\"base64 encoded content 1\", \"name\":\"attcahment1\"}, {\"content\":\"base64 encoded content 2\", \"name\":\"attcahment2\"}].
    */
    'attachment'?: Array<SendEmailAttachment>;
    /**
    * Pass the set of headers that shall be sent along the mail headers in the original email. \'sender.ip\' header can be set (only for dedicated ip users) to mention the IP to be used for sending transactional emails. Headers are allowed in `This-Case-Only` (i.e. words separated by hyphen with first letter of each word in capital letter), they will be converted to such case styling if not in this format in the request payload. For example, {\"Content-Type\":\"text/html\", \"charset\":\"iso-8859-1\", \"sender.ip\":\"1.2.3.4\"}
    */
    'headers'?: object;
    /**
    * Pass the set of attributes to customize the template. For example, {\"FNAME\":\"Joe\", \"LNAME\":\"Doe\"}
    */
    'attributes'?: object;
    /**
    * Tag your emails to find them more easily
    */
    'tags'?: Array<string>;

    static discriminator: string | undefined = undefined;

    static attributeTypeMap: Array<{name: string, baseName: string, type: string}> = [
        {
            "name": "emailTo",
            "baseName": "emailTo",
            "type": "Array<string>"
        },
        {
            "name": "emailBcc",
            "baseName": "emailBcc",
            "type": "Array<string>"
        },
        {
            "name": "emailCc",
            "baseName": "emailCc",
            "type": "Array<string>"
        },
        {
            "name": "replyTo",
            "baseName": "replyTo",
            "type": "string"
        },
        {
            "name": "attachmentUrl",
            "baseName": "attachmentUrl",
            "type": "string"
        },
        {
            "name": "attachment",
            "baseName": "attachment",
            "type": "Array<SendEmailAttachment>"
        },
        {
            "name": "headers",
            "baseName": "headers",
            "type": "object"
        },
        {
            "name": "attributes",
            "baseName": "attributes",
            "type": "object"
        },
        {
            "name": "tags",
            "baseName": "tags",
            "type": "Array<string>"
        }    ];

    static getAttributeTypeMap() {
        return SendEmail.attributeTypeMap;
    }
}

