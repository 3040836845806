/**
 * SendinBlue API
 * SendinBlue provide a RESTFul API that can be used with any languages. With this API, you will be able to :   - Manage your campaigns and get the statistics   - Manage your contacts   - Send transactional Emails and SMS   - and much more...  You can download our wrappers at https://github.com/orgs/sendinblue  **Possible responses**   | Code | Message |   | :-------------: | ------------- |   | 200  | OK. Successful Request  |   | 201  | OK. Successful Creation |   | 202  | OK. Request accepted |   | 204  | OK. Successful Update/Deletion  |   | 400  | Error. Bad Request  |   | 401  | Error. Authentication Needed  |   | 402  | Error. Not enough credit, plan upgrade needed  |   | 403  | Error. Permission denied  |   | 404  | Error. Object does not exist |   | 405  | Error. Method not allowed  |   | 406  | Error. Not Acceptable  | 
 *
 * The version of the OpenAPI document: 3.0.0
 * Contact: contact@sendinblue.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export class SendTransacSms {
    /**
    * Name of the sender. **The number of characters is limited to 11 for alphanumeric characters and 15 for numeric characters**
    */
    'sender': string;
    /**
    * Mobile number to send SMS with the country code
    */
    'recipient': string;
    /**
    * Content of the message. If more than 160 characters long, will be sent as multiple text messages
    */
    'content': string;
    /**
    * Type of the SMS. Marketing SMS messages are those sent typically with marketing content. Transactional SMS messages are sent to individuals and are triggered in response to some action, such as a sign-up, purchase, etc.
    */
    'type'?: SendTransacSms.TypeEnum;
    /**
    * Tag of the message
    */
    'tag'?: string;
    /**
    * Webhook to call for each event triggered by the message (delivered etc.)
    */
    'webUrl'?: string;

    static discriminator: string | undefined = undefined;

    static attributeTypeMap: Array<{name: string, baseName: string, type: string}> = [
        {
            "name": "sender",
            "baseName": "sender",
            "type": "string"
        },
        {
            "name": "recipient",
            "baseName": "recipient",
            "type": "string"
        },
        {
            "name": "content",
            "baseName": "content",
            "type": "string"
        },
        {
            "name": "type",
            "baseName": "type",
            "type": "SendTransacSms.TypeEnum"
        },
        {
            "name": "tag",
            "baseName": "tag",
            "type": "string"
        },
        {
            "name": "webUrl",
            "baseName": "webUrl",
            "type": "string"
        }    ];

    static getAttributeTypeMap() {
        return SendTransacSms.attributeTypeMap;
    }
}

export namespace SendTransacSms {
    export enum TypeEnum {
        Transactional = <any> 'transactional',
        Marketing = <any> 'marketing'
    }
}
