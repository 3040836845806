/**
 * SendinBlue API
 * SendinBlue provide a RESTFul API that can be used with any languages. With this API, you will be able to :   - Manage your campaigns and get the statistics   - Manage your contacts   - Send transactional Emails and SMS   - and much more...  You can download our wrappers at https://github.com/orgs/sendinblue  **Possible responses**   | Code | Message |   | :-------------: | ------------- |   | 200  | OK. Successful Request  |   | 201  | OK. Successful Creation |   | 202  | OK. Request accepted |   | 204  | OK. Successful Update/Deletion  |   | 400  | Error. Bad Request  |   | 401  | Error. Authentication Needed  |   | 402  | Error. Not enough credit, plan upgrade needed  |   | 403  | Error. Permission denied  |   | 404  | Error. Object does not exist |   | 405  | Error. Method not allowed  |   | 406  | Error. Not Acceptable  | 
 *
 * The version of the OpenAPI document: 3.0.0
 * Contact: contact@sendinblue.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export class GetEmailEventReportEvents {
    /**
    * Email address which generates the event
    */
    'email': string;
    /**
    * UTC date-time on which the event has been generated
    */
    'date': string;
    /**
    * Subject of the event
    */
    'subject'?: string;
    /**
    * Message ID which generated the event
    */
    'messageId': string;
    /**
    * Event which occurred
    */
    'event': GetEmailEventReportEvents.EventEnum;
    /**
    * Reason of bounce (only available if the event is hardbounce or softbounce)
    */
    'reason'?: string;
    /**
    * Tag of the email which generated the event
    */
    'tag'?: string;
    /**
    * IP from which the user has opened the email or clicked on the link (only available if the event is opened or clicks)
    */
    'ip'?: string;
    /**
    * The link which is sent to the user (only available if the event is requests or opened or clicks)
    */
    'link'?: string;
    /**
    * Sender email from which the emails are sent
    */
    'from'?: string;
    /**
    * ID of the template (only available if the email is template based)
    */
    'templateId'?: number;

    static discriminator: string | undefined = undefined;

    static attributeTypeMap: Array<{name: string, baseName: string, type: string}> = [
        {
            "name": "email",
            "baseName": "email",
            "type": "string"
        },
        {
            "name": "date",
            "baseName": "date",
            "type": "string"
        },
        {
            "name": "subject",
            "baseName": "subject",
            "type": "string"
        },
        {
            "name": "messageId",
            "baseName": "messageId",
            "type": "string"
        },
        {
            "name": "event",
            "baseName": "event",
            "type": "GetEmailEventReportEvents.EventEnum"
        },
        {
            "name": "reason",
            "baseName": "reason",
            "type": "string"
        },
        {
            "name": "tag",
            "baseName": "tag",
            "type": "string"
        },
        {
            "name": "ip",
            "baseName": "ip",
            "type": "string"
        },
        {
            "name": "link",
            "baseName": "link",
            "type": "string"
        },
        {
            "name": "from",
            "baseName": "from",
            "type": "string"
        },
        {
            "name": "templateId",
            "baseName": "templateId",
            "type": "number"
        }    ];

    static getAttributeTypeMap() {
        return GetEmailEventReportEvents.attributeTypeMap;
    }
}

export namespace GetEmailEventReportEvents {
    export enum EventEnum {
        Bounces = <any> 'bounces',
        HardBounces = <any> 'hardBounces',
        SoftBounces = <any> 'softBounces',
        Delivered = <any> 'delivered',
        Spam = <any> 'spam',
        Requests = <any> 'requests',
        Opened = <any> 'opened',
        Clicks = <any> 'clicks',
        Invalid = <any> 'invalid',
        Deferred = <any> 'deferred',
        Blocked = <any> 'blocked',
        Unsubscribed = <any> 'unsubscribed',
        Error = <any> 'error'
    }
}
